<script>
import {
    mdiChevronDown,
    mdiCheckBold,
    mdiHomeSwitchOutline,
    mdiBellOutline,
    mdiChevronLeft,
    mdiViewDashboardOutline,
    mdiFileDocumentMultipleOutline,
    mdiMenu,
    mdiLogout,
    mdiAlertCircleOutline,
    mdiAccountOutline,
    mdiAccountGroup,
    mdiChartAreaspline,
    mdiCircleSmall,
    mdiAccountDetails,
    mdiSecurity,
    mdiToyBrick,
    mdiWarehouse,
    mdiFileCabinet,
    mdiFileSign
} from '@mdi/js';
import apiService from "@/apiService";
import processErrors from "@/js/ProcessErrors";

export default {
    data() {
        return {
            isMobile: false,
            showComponent: false,
            ALL_COMPANY: null,
            errors: null,
            permissions: null,
            AVAILABLE_MODULES_DECODED: null,
            USERNAME: localStorage.getItem('username'),
            USER_EMAIL: localStorage.getItem('email'),
            COMPANY_NAME: localStorage.getItem('current_company'),
            PROFILE_AVATAR: localStorage.getItem('profile_photo'),
            COMPANY_LOGO: localStorage.getItem('company_logo'),
            AVAILABLE_MODULES: localStorage.getItem('available_modules'),
            arrowLeftIcon: mdiChevronLeft,
            arrowDownIcon: mdiChevronDown,
            checkedIcon: mdiCheckBold,
            companyIcon: mdiHomeSwitchOutline,
            noticeIcon: mdiBellOutline,
            menuIcon: mdiMenu,
            alertIcon: mdiAlertCircleOutline,
            logoutIcon: mdiLogout,
            moduleArr: [],
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkScreenSize); // Remove resize event listener when component is unmounted
    },
    created() {
        document.title = 'Precast ERP';
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize); // Listen for window resize events
        const token = localStorage.getItem('token');
        if (token) {
            const user_id = localStorage.getItem('user_id');
            if (!user_id) {
                this.logout();
            }
            this.ALL_COMPANY = JSON.parse(localStorage.getItem('available_companies'));
            this.reloadAllModules();
            console.log("All Modules Loaded");
        }
        this.showComponent = !!token;

        if (!this.showComponent) {
            const element = document.getElementById("app");
            element.style.height = "calc(100% - 36px)"
        }
        // if (this.showComponent) {
        //     this.retrievedPermissionList()
        // }
    },
    methods: {
        async changeCompany($id) {
            try {
                const response = await apiService(`change-company/${$id}`, 'put');
                if (response.status === true) {
                    const company_id = response.data.company.id;
                    const current_company = response.data.company.name;
                    const company_code = response.data.company.company_code;
                    const company_logo = response.data.company.logo;
                    localStorage.setItem('company_id', company_id);
                    localStorage.setItem('current_company', current_company);
                    localStorage.setItem('company_code', company_code);
                    localStorage.setItem('company_logo', company_logo);
                    location.reload()
                } else {
                    this.error = 'Something when wrong';
                }
            } catch (error) {
                this.errors = error.message;
            }
            // finally {
            //     this.loading = false;
            // }
        },
        async reloadAllModules() {
            try {
                const response = await apiService(`modules`, 'get');
                if (response.status === false) {
                    processErrors()
                } else {
                    this.AVAILABLE_MODULES_DECODED = response.data.modules
                }
            } catch (error) {
                processErrors()
            }
        },
        logout() {
            // Clear local storage
            localStorage.clear();

            // Optionally, you might want to redirect the user to the login page
            // You can use Vue Router for this:
            location.reload();
        },
        getModuleIcon(val) {
            switch (val) {
                case 'dashboard_icon':
                    return mdiViewDashboardOutline
                case 'kpi_icon':
                    return mdiChartAreaspline
                case 'project_icon':
                    return mdiFileDocumentMultipleOutline
                case 'customer_icon':
                    return mdiAccountDetails
                case 'hr_icon':
                    return mdiAccountGroup
                case 'user_profile_icon':
                    return mdiAccountOutline
                case 'access_icon':
                    return mdiSecurity
                case 'mould_icon':
                    return mdiToyBrick
                case 'warehouse_icon':
                    return mdiWarehouse
                case 'file_icon':
                    return mdiFileCabinet
                case 'contract_icon':
                    return mdiFileSign
                default:
                    return mdiCircleSmall
            }
        },
        setCurrentModule(module_id, sub_module_id = 0) {
            localStorage.setItem('module_id', module_id)
            if (sub_module_id > 0) {
                localStorage.setItem('sub_module_id', sub_module_id)
            }
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth < 768; // Set isMobile to true if screen width is less than 768 pixels
        },
        goBack() {
            this.$router.go(-1); // Go back to the previous page
        },
    },

}
</script>
<script setup>
import { ref } from 'vue'

const drawer = ref(null)
</script>
<template>
    <v-layout class="set-custom-height">
        <v-main>
            <template v-if="showComponent === true">
                <v-navigation-drawer v-model="drawer" :temporary="isMobile" :permanent="!isMobile">
                    <template v-if="AVAILABLE_MODULES_DECODED">
                        <v-list>
                            <v-list-item :prepend-avatar="PROFILE_AVATAR" :subtitle="USER_EMAIL"
                                :title="USERNAME"></v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list :lines="false" density="compact" nav>
                            <template v-for="module in AVAILABLE_MODULES_DECODED" :key="module.id">
                                <v-list-item v-if="module.submodule.length === 0" @click="setCurrentModule(module.id)"
                                    :prepend-icon="getModuleIcon(module.icon)" :title="module.name" :to="module.link"
                                    color="deep-purple-accent-2">
                                </v-list-item>
                                <v-list-group v-else :value="module.name">
                                    <template v-slot:activator="{ props }">
                                        <v-list-item v-bind="props" :prepend-icon="getModuleIcon(module.icon)"
                                            :title="module.name" class="rounded">
                                        </v-list-item>
                                    </template>
                                    <v-list-item v-for="(child, index2) in module.submodule"
                                        @click="setCurrentModule(module.id, child.id)" :key="index2" :title="child.name"
                                        :to="child.link" class="cursor-pointer text-caption rounded"
                                        color="deep-purple-accent-2"></v-list-item>
                                </v-list-group>
                            </template>
                        </v-list>
                    </template>
                    <div class="d-flex flex-column" v-else>
                        <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                        <v-divider></v-divider>
                        <v-skeleton-loader type="heading"></v-skeleton-loader>
                        <v-skeleton-loader type="heading"></v-skeleton-loader>
                        <v-skeleton-loader type="heading"></v-skeleton-loader>
                    </div>
                </v-navigation-drawer>
                <v-toolbar class="bg-white">
                    <template v-slot:prepend>
                        <v-btn :icon="menuIcon" @click.stop="drawer = !drawer"></v-btn>
                        <v-btn class="d-block d-md-none" :icon="arrowLeftIcon" @click="goBack"></v-btn>
                    </template>
                    <v-btn class="ms-5 text-grey-darken-1" :icon="noticeIcon">
                    </v-btn>
                    <!--                    <template v-if="$vuetify.display.smAndUp">-->
                    <v-divider class="mx-3 align-self-center" length="24" thickness="2" vertical></v-divider>
                    <div id="menu-activator" class="d-flex align-center h-100 cursor-pointer">

                        <img v-if="COMPANY_LOGO !== 'null'" :src="COMPANY_LOGO" class="h-100 pa-4" :alt="COMPANY_NAME">
                        <span class="font-weight-bold d-none d-md-block mr-3">
                            {{ COMPANY_NAME || 'Administration' }}
                        </span>
                        <v-icon :icon="arrowDownIcon"></v-icon>
                    </div>
                    <v-menu activator="#menu-activator">
                        <v-dialog max-width="500">
                            <template v-slot:activator="{ props: activatorProps }">
                                <v-list>
                                    <v-list-item v-bind="activatorProps" class="rounded" title="Switch Company">
                                    </v-list-item>
                                    <v-list-item title="Logout" @click="logout" class="rounded"
                                        color="deep-purple-accent-2"></v-list-item>
                                </v-list>
                            </template>

                            <template v-slot:default="{ isActive }">
                                <v-card title="Switch Company">
                                    <v-card-text>
                                        <v-card subtitle="CURRENT SELECTED" :title="COMPANY_NAME" theme="dark"
                                            class="mb-3">
                                            <template v-slot:prepend>
                                                <div class="pa-2 bg-white rounded">
                                                    <v-icon color="deep-purple-accent-2" :icon="companyIcon"></v-icon>
                                                </div>
                                            </template>
                                            <template v-slot:append>
                                                <v-icon color="success" :icon="checkedIcon"></v-icon>
                                            </template>
                                        </v-card>
                                        <template v-if="ALL_COMPANY.length > 1">
                                            <span class="mb-3 d-block font-weight-bold">Companies</span>
                                            <template v-for="own_company in ALL_COMPANY" :key="own_company.id">
                                                <v-list-item :title="own_company.name"
                                                    v-if="COMPANY_NAME !== own_company.name"
                                                    @click="changeCompany(own_company.id)">
                                                </v-list-item>
                                            </template>
                                        </template>
                                        <template v-if="errors">
                                            <v-chip :prepend-icon="alertIcon" class="text-red-accent-4 mt-2">
                                                {{ errors }}
                                            </v-chip>
                                        </template>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>

                                        <v-btn text="Close" @click="isActive.value = false"></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-menu>
                    <!--                    </template>-->
                </v-toolbar>
            </template>
            <v-container id="set_inner_container_height" :class="showComponent ? '' : 'd-flex'">
                <router-view />
            </v-container>
            <v-footer class="border-t">
                <small class="text-grey">© 2024 - Precast ERP. All rights reserved.</small>
            </v-footer>
        </v-main>
    </v-layout>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: 'Figtree', sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    /* Color of the scrollbar thumb */
    border-radius: 6px;
    /* Rounded corners */
}

::-webkit-scrollbar-track {
    background-color: white;
    /* Color of the scrollbar track */
}

body,
html {
    background-color: #f9fafb;
    height: 100%;
}

#app {
    height: calc(100% - 100px);
}

.set-custom-height {
    height: 100%;
}

#set_inner_container_height {
    min-height: 100%;
}

.v-breadcrumbs-item {
    padding: 0 !important;
}

.custom-table-block {
    padding: 1rem;
    border-radius: 0.375rem;
    background-color: white;
}

.text-type-main {
    font-size: 16px;
    font-weight: 500;
}

.text-type-sub {
    font-size: 14px;

    &-mini {
        font-size: 12px;
    }
}

.light-grey-border {
    border: 1px solid #e9ecef;
}

.bg-light-grey {
    background-color: #e9ecef;
}

.profile-avatar {
    height: 40px;
    width: 40px;
}

.swal2-container {
    z-index: 9999
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent !important;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent !important;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent !important;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent !important;
}

input::placeholder {
    color: transparent !important;
}

textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent !important;
}

textarea:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent !important;
}

textarea::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent !important;
}

textarea:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent !important;
}

textarea::placeholder {
    color: transparent !important;
}

.swal2-confirm.swal2-styled,
.swal2-cancel.swal2-styled,
.swal2-confirm.swal2-styled.swal2-default-outline {
    color: white !important;
}

.swal2-html-container ul {
    list-style-type: none;
}

.dp__pointer.dp__input.dp__input_icon_pad.dp__input_reg {
    border: 1px solid #BDBDBD;
}

.dp__pointer.dp__input.dp__input_icon_pad.dp__input_reg:hover {
    border: 1px solid black;
}

.dp__disabled.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg:hover,
.dp__disabled.dp__input_readonly.dp__input.dp__input_icon_pad.dp__input_reg:disabled {
    border: 1px solid #E0E0E0;
    color: #BDBDBD;
    background-color: white;
}
</style>