import {
     createApp
} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

const app = createApp(App)

// 添加警告处理器
app.config.warnHandler = (msg, instance, trace) => {
     if (msg.includes('<Suspense> is an experimental feature') ||
          msg.includes('__VUE_PROD_HYDRATION_MISMATCH_DETAILS__')) {
          // 忽略这些特定的警告
          return
     }
     // 对其他警告保持默认行为
     console.warn(msg, instance, trace)
}

app.use(router)
     .use(vuetify)
     .mount('#app')