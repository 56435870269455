import axios from 'axios';
import setAuthToken from "@/axios";
import Swal from "sweetalert2";

let BASE_URL      = null;
const currentHost = window.location.host;
const token       = localStorage.getItem('token');
setAuthToken(token)
if (currentHost === 'localhost:8080' || currentHost === '192.168.0.183:8080' || currentHost === 'localhost' || currentHost === 'testing.casterp.host') {
    BASE_URL = 'https://staging.casterp.host/api';
    console.log(token)
} else {
    BASE_URL = 'https://production.casterp.host/api';
}

const company_id = localStorage.getItem('company_id');

async function fetchData(API_URL, type, params = {}, file = false, signal = null) {
    let response;
    const url = `${BASE_URL}/${API_URL}`;
    switch (type) {
        case 'put':
            response = await axios.put(url, params);
            break;
        case 'delete':
            response = await axios.delete(url);
            break;
        case 'post':
            try {
                response = await axios.post(url, params);
            } catch (error) {
                let extra_msg = 'Something When Wrong.';
                if (error.response.data.message) {
                    extra_msg = error.response.data.message
                }
                return {
                    'status': false,
                    'message': `${extra_msg}`
                }
            }
            break;
        case 'get':
        default:
            try {
                const localParams = {
                    current_company_id: company_id
                }
                const allParams   = {...localParams, ...params};
                if (file) {
                    response = await axios.get(url, {
                        responseType: 'blob',
                        params: allParams
                    });
                    return response
                } else {
                    response = await axios.get(url, {
                        params: allParams, signal
                    });
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    let extra_msg = '';
                    if (error.response.data.message) {
                        extra_msg = error.response.data.message
                    }
                    // return {
                    //     'status': false,
                    //     'message': `[${error.code}] - ${error.message} - ${extra_msg}`
                    // }
                    Swal.fire({
                                  icon: "error",
                                  title: "Oops...",
                                  text: extra_msg
                              });
                }
                return;
            }

    }
    if (response.status !== 200) {
        return response
    }
    return response.data
}

export default fetchData;