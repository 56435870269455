import {createRouter, createWebHashHistory} from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        // component: () => import(/* webpackChunkName: "about" */ '../views/home-dashboard.vue')
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectList.vue')
    },
    {
        path: '/Dashboard',
        name: 'Dashboard',
        // component: () => import(/* webpackChunkName: "about" */ '../views/home-dashboard.vue')
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectList.vue')
    },
    {
        path: '/UserProfile',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserProfileView.vue')
    },
    {
        path: '/HR/CreateUser',
        name: 'Create UserProfile',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/UserProfileCreate.vue')
    },
    {
        path: '/AC/UserAccessManage/:user_id/:name',
        name: 'User Access Control',
        component: () => import(/* webpackChunkName: "about" */ '../views/AccessControl/UserAccessControlManage.vue')
    },
    {
        path: '/AC/UserAccess',
        name: 'User Access',
        component: () => import(/* webpackChunkName: "about" */ '../views/AccessControl/UserAccessControlView.vue')
    },
    {
        path: '/AC/AccessControlLevel',
        name: 'Access Level Control',
        component: () => import(/* webpackChunkName: "about" */ '@/views/AccessControl/AccessControlView.vue')
    },
    {
        path: '/AC/AccessControlLevelEdit/:id',
        name: 'Manage User Level',
        component: () => import(/* webpackChunkName: "about" */ '@/views/AccessControl/AccessControlEdit.vue')
    },
    {
        path: '/AC/AccessControlLevelCreate',
        name: 'Create User Level',
        component: () => import(/* webpackChunkName: "about" */ '@/views/AccessControl/AccessControlCreate.vue')
    },
    {
        path: '/Login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/UserLogin.vue')
    },
    {
        path: '/ViewCompanyProfile',
        name: 'ViewCompanyProfile',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/ViewCompanyProfile.vue')
    },
    {
        path: '/CompanyProfileAction',
        name: 'CompanyProfileAction',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/CompanyProfileAction.vue')
    },
    {
        path: '/Projects',
        name: 'ProjectList',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectList.vue'),
        meta: {
            pageDetails: {
                title: 'Projects'
            }
        }
    },
    {
        path: '/Project Overall',
        name: 'ProjectListView',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectListViewOnly.vue'),
        meta: {
            pageDetails: {
                title: 'Projects'
            }
        }
    },
    {
        path: '/Projects/Archive',
        name: 'Archive Project List',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ArchiveProjectView.vue'),
        meta: {
            pageDetails: {
                title: 'Archive Projects'
            }
        }
    },
    {
        path: '/Customer/View',
        name: 'Customers',
        component: () => import(/* webpackChunkName: "about" */ '../views/customer/customerView.vue')
    },
    {
        path: '/HR/Position',
        name: 'Arrange Position',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/PositionView.vue')
    },
    {
        path: '/HR/Department',
        name: 'Department',
        component: () => import(/* webpackChunkName: "about" */ '../views/Department/DepartmentView.vue')
    },
    {
        path: '/HR/Department/:department_id',
        name: 'Department Information',
        component: () => import(/* webpackChunkName: "about" */ '../views/Department/DepartmentDetailView.vue')
    },
    {
        path: '/Project/Create',
        name: 'Create Project',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/CreateProject.vue')
    },
    {
        path: '/Project/Edit/:project_id',
        name: 'Edit Project',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectEdit.vue')
    },
    {
        path: '/Project/View/:project_id',
        name: 'Project Information',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ViewProjectInfo.vue')
    },
    {
        path: '/Project/MobileVerticalMarking/:project_id',
        name: 'Mobile Vertical Marking',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/MobileVerticalMarking.vue')
    },
    {
        path: '/Project/View/Drawings/:project_id',
        name: 'Drawings',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ViewDrawingsByProject.vue')
    },
    {
        path: '/Projects/DeliveryOrders',
        name: 'Delivery Orders',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/DeliveryOrdersView.vue')
    },
    {
        path: '/Projects/NCR',
        name: 'NCR',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/NcrView.vue')
    },
    {
        path: '/Project/BatchUpdate/:project_id/:project_name/:type/:id_list',
        name: 'Batch Update',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/BatchUpdate.vue')
    },
    {
        path: '/Project/ViewProjectBound/:project_id/:site_component_id',
        name: 'Submit Project Status',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ViewProjectBound.vue')
    },
    {
        path: '/Project/ViewProjectBoundBySection/:project_id/:section_id/:project_name',
        name: 'View Project Bound By Section',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectStatusBySection.vue')
    },
    {
        path: '/CreateSection/:project_id/',
        name: 'Create Section',
        component: () => import(/* webpackChunkName: "about" */ '../views/Section/CreateSection.vue')
    },
    {
        path: '/Project/ViewProjectBoundByCategory/:project_id/:category_id/:project_name',
        name: 'View Project Bound By Category',
        component: () => import(/* webpackChunkName: "about" */ '../views/Project/ProjectStatusByCategory.vue')
    },
    {
        path: '/Mould/MouldList/:project_id?',
        name: 'Mould List',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mould/MouldView.vue')
    },
    {
        path: '/Mould/ProductionPlanning',
        name: 'Mould Task Planning',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mould/MouldTask.vue')
    },
    {
        path: '/Mould/ProductionPlanning/Create',
        name: 'Create Mould Task Planning',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mould/MouldTaskCreate.vue')
    },
    {
        path: '/Drawings',
        name: 'Drawings List',
        component: () => import(/* webpackChunkName: "about" */ '../views/Drawings/DrawingsView.vue')
    },
    {
        path: '/DrawingsKPI',
        name: 'Drawings KPI',
        component: () => import(/* webpackChunkName: "about" */ '../views/KPI/DrawingsKPI.vue')
    },
    {
        path: '/FileManager/:id?',
        name: 'File Manager',
        component: () => import(/* webpackChunkName: "about" */ '../views/File/FileManager.vue')
    },
    {
        path: '/FileManager/View/:id',
        name: 'File Manager Folder View',
        component: () => import(/* webpackChunkName: "about" */ '../views/File/FileManagerFolderView.vue')
    },
    {
        path: '/DrawingsKPI/SetTargets',
        name: 'Set Targets',
        component: () => import(/* webpackChunkName: "about" */ '../views/KPI/DrawingSetDailyTarget.vue')
    },
    {
        path: '/Achievement',
        name: 'Achievement',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/AchievementView.vue')
    },
    {
        path: '/Logs/ViewAll/:id/:project_id',
        name: 'Logs',
        component: () => import(/* webpackChunkName: "about" */ '../views/Log/LogView.vue')
    },
    {
        path: '/WH/Materials',
        name: 'Materials',
        component: () => import(/* webpackChunkName: "about" */ '../views/Materials/MaterialsView.vue')
    },
    {
        path: '/WH/Material/Create',
        name: 'Create Material',
        component: () => import(/* webpackChunkName: "about" */ '../views/Materials/MaterialCreate.vue')
    },
    {
        path: '/WH/Material/:id',
        name: 'Edit Material',
        component: () => import(/* webpackChunkName: "about" */ '../views/Materials/MaterialEdit.vue')
    },
    {
        path: '/WH/MaterialBom/:id',
        name: 'Material Bom',
        component: () => import(/* webpackChunkName: "about" */ '../views/Materials/MaterialBom.vue')
    },
    {
        path: '/WH/Bom',
        name: 'Bill Of Materials',
        component: () => import(/* webpackChunkName: "about" */ '../views/Products/ProductsView.vue')
    },
    {
        path: '/WH/Product/Create',
        name: 'Create Product',
        component: () => import(/* webpackChunkName: "about" */ '../views/Products/ProductCreate.vue')
    },
    {
        path: `/WH/Product/:id/:project_id`,
        name: 'Edit Product',
        component: () => import(/* webpackChunkName: "about" */ '../views/Products/ProductEdit.vue')
    },
    {
        path: '/WH/ProductBom/:id',
        name: 'Product Bom',
        component: () => import(/* webpackChunkName: "about" */ '../views/Products/ProductBom.vue')
    },
    {
        path: '/WH/Suppliers',
        name: 'Suppliers',
        component: () => import(/* webpackChunkName: "about" */ '../views/Suppliers/SuppliersList.vue')
    },
    {
        path: '/WH/Suppliers/:id',
        name: 'Suppliers View',
        component: () => import(/* webpackChunkName: "about" */ '../views/Suppliers/SupplierView.vue')
    },
    {
        path: '/Settings/ExchangeRate',
        name: 'Exchange Rate',
        component: () => import(/* webpackChunkName: "about" */ '../views/Settings/ExchangeRate.vue')
    },
]

const router = createRouter({
                                history: createWebHashHistory(),
                                routes
                            })

router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('token');
    if (to.name !== 'Login' && !isAuthenticated) {
        next({name: 'Login'});
    } else if (isAuthenticated && to.name === 'Login') {
        next({name: 'Dashboard'});
    } else {
        next();
    }
});
export default router
